import React from 'react';
import {AnimatePresence, AnimateSharedLayout} from 'framer-motion';
import {AdapterLuxon} from "@mui/x-date-pickers/AdapterLuxon";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {SnackbarProvider} from "notistack";

import "./src/stylesheets/styles.scss";
import MuiThemeProvider from "./src/components/MuiThemeProvider";

export const wrapPageElement = ({element}) => (
  <LocalizationProvider dateAdapter={AdapterLuxon}>
    <MuiThemeProvider>
      <SnackbarProvider maxSnack={3}>
        <AnimateSharedLayout>
          <AnimatePresence mode='wait'>
            {element}
          </AnimatePresence>
        </AnimateSharedLayout>
      </SnackbarProvider>
    </MuiThemeProvider>
  </LocalizationProvider>
);